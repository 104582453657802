<template>
	<div class="client-information">
		<div class="top-img">
			<div class="top-name">客户信息库</div>
			<div class="search-input">
				<el-input v-model.trim="conPO.search" placeholder="请输入客户名称等关键词" maxlength="200" @keyup.enter.native="handleQuery"></el-input>
				<span class="search-btn" @click="handleQuery">查询</span>
			</div>
		</div>
		<div class="title-card">
			<div class="title-box">
				<div class="title">客户信息库</div>
				<div class="result">
					<span>共找到</span>
					<span class="num">{{ total }}</span>
					<span>条结果</span>
				</div>
			</div>
			<div class="condition-box">
				<div class="condition-item">
					<div class="title">省份</div>
					<div class="item-box">
						<div class="all-btn" :class="isProAll ? 'all-btn-select' : ''" @click="proAll">全部</div>
						<div class="name-box" :class="provincesStatus ? 'name-open' : ''">
							<div class="name" :class="proSelect.indexOf(item) !== -1 ? 'name-select' : ''" v-for="item in regionList" :key="item.code" @click="selectPro(item)">
								{{ item.name }}
							</div>
						</div>
					</div>
					<div class="open" @click="foldProvinces">
						<span>{{ provincesStatus ? "收起" : "展开" }}</span>
						<span class="iconfont icon-sanjiaoxing" :class="provincesStatus ? 'iconfont-open' : ''"></span>
					</div>
				</div>
				<div class="condition-item" v-show="cityData.length != 0">
					<div class="title">地级市</div>
					<div class="item-box">
						<div class="name-box" :class="cityStatus ? 'name-open' : ''">
							<div class="name" :class="citySelect.indexOf(item) !== -1 ? 'name-select' : ''" v-for="item in cityData" :key="item.code" @click="selectCity(item)">
								{{ item.name }}
							</div>
						</div>
					</div>
					<div class="open" @click="foldCity">
						<span>{{ cityStatus ? "收起" : "展开" }}</span>
						<span class="iconfont icon-sanjiaoxing" :class="cityStatus ? 'iconfont-open' : ''"></span>
					</div>
				</div>
				<div class="condition-item" v-show="selectedProData.length != 0 || selectedCityData.length != 0">
					<div class="title">已选条件</div>
					<div class="selected-box">
						<div class="name" v-for="(item, i) in selectedProData" :key="item.code">
							<div>{{ item.name }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 1)"></div>
						</div>
						<div class="name" v-for="(item, i) in selectedCityData" :key="item.code">
							<div>{{ item.name }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 2)"></div>
						</div>
					</div>
					<div class="empty-btn" @click="emptySelect">清空筛选条件</div>
				</div>
			</div>
		</div>
		<div class="list-box">
			<div class="empty-box" v-show="total == 0">
				<empty :name="'暂无数据'"></empty>
			</div>
			<div class="list-item" v-for="(item, index) in pageClient" :key="index" @click="toDetail(item)">
				<img class="img-left" v-if="item.logoUrl" @dragstart.prevent :src="item.logoUrl" alt="" />
				<div class="img-left-logo" v-else style="background-color: #6daae2">
					<div class="logo-img">{{ item.logoImg }}</div>
				</div>
				<div class="left-right">
					<div class="name-top">
						<el-tooltip effect="light" :content="item.clientName" placement="bottom-start">
							<div class="text-ellipsis">
								{{ item.clientName }}
							</div>
						</el-tooltip>
					</div>
					<div class="bottom-box" style="margin-bottom: 10px">
						<div class="tag-bottom">
							<div class="tag-item">企业法人：{{ item.legalPerson || "-" }}</div>
							<div class="tag-item">注册资本：{{ item.registeredCapital || "-" }}</div>
							<div class="tag-item">成立日期：{{ item.establishDate || "-" }}</div>
							<div class="tag-item">联系电话：{{ item.phone || "-" }}</div>
						</div>
					</div>
					<div class="bottom-box">
						<div class="tag-bottom">
							<div class="tag-item">
								企业地址：
								<el-tooltip effect="light" :content="item.address" placement="bottom-start" v-if="item.address">
									<span class="address text-ellipsis">{{ item.address }}</span>
								</el-tooltip>
								<span v-else>-</span>
							</div>
							<div class="tag-item">
								企业官网：
								<el-tooltip effect="light" :content="item.websiteLink" placement="bottom-start" v-if="item.websiteLink">
									<span class="link-item text-ellipsis" @click.stop="toLink(item.websiteLink)">{{ item.websiteLink }}</span>
								</el-tooltip>
								<span class="link" v-else>-</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pagination-box">
			<pagination ref="pagination" :total="total" @change="paginChange" v-show="total != 0" :key="paginationKey"></pagination>
		</div>
		<login-modal ref="loginModal"></login-modal>
		<member-modal ref="memberModal"></member-modal>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import LoginModal from "@/components/loginModal";
import Empty from "@/components/Empty";
import MemberModal from "@/components/MemberModal";
import {mapGetters} from "vuex";
export default {
	components: {
		Pagination,
		LoginModal,
		Empty,
		MemberModal,
	},
	data() {
		return {
			cityData: [], // 市级数据
			provincesStatus: false, // 省份展开收起状态
			cityStatus: false, // 地级市展开收起状态
			proSelect: [], // 选中的省份
			isProAll: true, // 省份是否全选
			citySelect: [], // 选中的地级市
			selectedProData: [], // 已选的省级数据
			selectedCityData: [], // 已选地级市数据
			pageReport: [],
			conPO: {
				search: "",
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			total: 0,
			pageClient: [],
			paginationKey: "",
		};
	},
	computed: {
		...mapGetters(["regionList"]),
	},
	mounted() {
		this.getClientInfo();
	},
	methods: {
		// 点击搜索
		handleQuery() {
			if (this.$session.getUsers()) {
                this.$refs.pagination.page = 1;
                this.conPO.pageNum = 1;
                this.conPO.pageSize = 10;
                this.getClientInfo();
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		coloring() {
			let colors = ["#90cfb7", "#8ca3cc", "#e8ab6f", "#6daae2", "#c8b7d8"];
			let index = Math.floor(Math.random() * 5);
			return colors[index];
		},

		// 客户信息列表
		getClientInfo() {
			let loading=this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			let {conPO, proSelect, citySelect} = this;
			let provinces = [];
			proSelect.map(item => {
				provinces.push(item.code);
			});
			conPO.province = provinces;

			let citys = [];
			citySelect.map(item => {
				citys.push(item.code);
			});
			conPO.city = citys;
			this.$api.industry
				.getClientInfo(conPO)
				.then(res => {
					res.rows.forEach(item => {
						if (!item.logoUrl) {
							this.$api.industry
								.getCompanyName({
									companyName: item.clientName,
								})
								.then(ress => {
									item.logoImg = ress.msg;
									this.$forceUpdate();
								})
								.catch(msg => {
									if (msg?.msg) {
										this.$message.error(msg?.msg);
									}
								});
						}
					});
					this.pageClient = res.rows;
					this.total = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(()=>{
					loading.close();
				});
		},
		// 请求市区城
		getCity(item) {
			let obj = {
				parentRegion: item.regionId,
				isLazyLoad: 10011001,
				state: 10021001,
				hasChildren: true
			}
			this.$api.system.regionList(obj).then(res => {
				if (res.data.length > 0) {
					res.data.map(item => {
						item.name = item.regionFullname
						item.code = item.unionCode
					})
					let data = res.data;
					this.cityData = [...data];
				}
			});
		},
		// 展开收起省份
		foldProvinces() {
			this.provincesStatus = !this.provincesStatus;
		},
		// 展开收起地级市
		foldCity() {
			this.cityStatus = !this.cityStatus;
		},
		// 单选省份
		selectPro(item) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				let {proSelect, cityData, selectedProData, citySelect, selectedCityData} = this;
				let valueIndex = proSelect.indexOf(item);
				if (valueIndex == -1) {
					this.proSelect.push(item);
					this.selectedProData.push(item)
					if (this.proSelect.length == 0 && this.citySelect.length == 0) {
						this.isProAll = true;
					} else {
						this.isProAll = false;
					}
					// 判断市级数据中是否已经存在当前省的市级数据
					if (citySelect.some(key => key.code.slice(0, 2) == item.code.slice(0, 2))) {
						// 处理市级数据（不需要当前省级的市级数据）
						let newCitySelect = [];
						citySelect.forEach(key => {
							if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
								newCitySelect.push(key);
							}
						});
						this.citySelect = newCitySelect;
						// 处理已选条件的市级数据（不需要当前省级的市级数据）
						let newSelectedCityData = [];
						selectedCityData.forEach(key => {
							if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
								newSelectedCityData.push(key);
							}
						});
						this.selectedCityData = newSelectedCityData; 
					}
					this.handleQuery();
					// 选择
					this.getCity(item);
				} else {
					// 取消选择
					proSelect.splice(valueIndex, 1);
					// 删除对应的市级数据
					let cityResult = [];
					cityData.forEach(key => {
						if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
							cityResult.push(key);
						}
					});
					this.cityData = cityResult;
					// （删除已选的省级数据）比较 item和selectedProData取出不同的值
					let itemArr = [item];
					let selectedCodes = [];
					itemArr.forEach(({name}) => {
						selectedCodes.push(name);
					});
					let selectedResult = selectedProData.filter(({name}) => !selectedCodes.includes(name));
					this.selectedProData = selectedResult;
					this.handleQuery();
				}
				if (this.proSelect.length == 0 && this.citySelect.length == 0) {
					this.isProAll = true;
				} else {
					this.isProAll = false;
				}
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 全选省份
		proAll() {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				if (this.proSelect.length == 0 && this.citySelect.length == 0) {
					return;
				}
				this.isProAll = !this.isProAll;
				if (this.isProAll) {
					this.selectedProData = [];
					this.proSelect = [];
					this.cityData = [];
					this.selectedCityData = [];
					this.citySelect = [];
				}
				this.handleQuery();
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 单选地级市
		selectCity(item) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				let {citySelect, selectedCityData, proSelect, selectedProData} = this;
				let valueIndex = citySelect.indexOf(item);
				if (valueIndex == -1) {
					// 选择
					citySelect.push(item);
					this.selectedCityData = [...selectedCityData, ...[item]];
					// 处理省级数据（不需要当前市级的省级数据）
					let newCitySelect = [];
					proSelect.forEach(key => {
						if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
							newCitySelect.push(key);
						}
					});
					this.proSelect = newCitySelect;
					// 处理已选条件的省级数据（不需要当前市级的省级数据）
					let newSelectedProData = [];
					selectedProData.forEach(key => {
						if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
							newSelectedProData.push(key);
						}
					});
					this.selectedProData = newSelectedProData;
					if (this.proSelect.length == 0) {
						this.isProAll = false;
					}
					this.handleQuery();
				} else {
					// 取消选择
					citySelect.splice(valueIndex, 1);
					// 比较 item和selectedCityData取出不同的值
					let itemArr = [item];
					let selectedCodes = [];
					itemArr.forEach(({code}) => {
						selectedCodes.push(code);
					});
					let selectedResult = selectedCityData.filter(({code}) => !selectedCodes.includes(code));

					if (selectedResult.length == 0) {
						// 无选中的市时
						this.isProAll = true;
					}
					this.selectedCityData = selectedResult;
					this.handleQuery();
				}
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 删除单条所选内容
		delSelectData(item, index, type) {
			if (type == 1) {
				// 删除省
				let {proSelect, citySelect, cityData} = this;
				this.selectedProData.splice(index, 1);
				// 删除对应的市级数据
				let cityResult = [];
				cityData.forEach(key => {
					if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
						cityResult.push(key);
					}
				});
				this.cityData = cityResult;
				let newData = proSelect.filter(key => ![item.name].includes(key.name));
				// if (newData.length == 0 && this.selectedCityData.length == 0) {
				//   // 无选中的省，市
				//   this.isProAll = true;
				//   this.selectedProData = [];
				//   this.proSelect = [];
				//   this.cityData = [];
				//   this.selectedCityData = [];
				//   this.citySelect = [];
				// }
				this.proSelect = newData;
				if (proSelect.length == 1 && citySelect.length == 0) {
					this.cityData = [];
				}
				this.getTenderInfo();
				if (this.proSelect.length == 0 && this.citySelect.length == 0) {
					this.isProAll = true;
				} else {
					this.isProAll = false;
				}
			} else if (type == 2) {
				// 删除市
				let {citySelect, selectedProData} = this;
				this.selectedCityData.splice(index, 1);
				let newData = citySelect.filter(key => ![item.name].includes(key.name));
				if (newData.length == 0) {
					this.selectedCityData = [];
					this.citySelect = [];
				}
				if (newData.length == 0 && this.proSelect.length == 0) {
					this.isProAll = true;
				}
				this.citySelect = newData;
				if (citySelect.length == 1 && selectedProData.length == 0) {
					this.cityData = [];
				}
				this.handleQuery();
			}
		},
		// 清空筛选条件
		emptySelect() {
			this.cityData = [];
			this.proSelect = [];
			this.isProAll = true;
			this.citySelect = [];
			this.selectedProData = [];
			this.selectedCityData = [];
			this.handleQuery();
		},
		// 点击跳转公司官网
		toLink(url) {
			if (!url) {
				return;
			}
			this.$confirm("您即将跳转进入第三方网站，慧决策平台不对该网站内容及真实性负责，请注意您的账号和财产安全", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				window.open(url, "_blank");
			});
		},
		// 点击列表查看详情
		toDetail(item) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				let type = item.clientType == "60661001" ? 1 : 2;

				if (this.conPO.pageNum == 1) {
					// 第一页数据
					this.$api.personalCenter
						.isMyClient({clientId: item.clientId})
						.then(res => {
							// if (res.data == 0) {
							// 	let url = this.$router.resolve({
							// 		path: `/client/client-detail?id=${item.clientId}&type=${type}`,
							// 	});
							// 	window.open(url.href, "_blank");
							// } else {
								let url = this.$router.resolve({
									path: `/client/consumer-detail?id=${item.clientId}&type=${type}&pathType=2`,
								});
								window.open(url.href, "_blank");
							// }
						})
						.catch(msg => {
							if (msg?.msg) {
								this.$message.error(msg?.msg);
							}
						});
				} else {
                    this.$api.industry.selectClientInfo({clientId: item.clientId}).then(() => {
                        this.$api.personalCenter.isMyClient({clientId: item.clientId}).then(res => {
                            // if (res.data == 0) {
                            //     let url = this.$router.resolve({
                            //         path: `/client/client-detail?id=${item.clientId}&type=${type}`,
                            //     });
                            //     window.open(url.href, "_blank");
                            // } else {
                                let url = this.$router.resolve({
                                    path: `/client/consumer-detail?id=${item.clientId}&type=${type}&pathType=2`,
                                });
                                window.open(url.href, "_blank");
                            // }
                        }).catch(msg => {
                            if (msg?.msg) {
                                this.$message.error(msg?.msg);
                            }
                        });
                    }).catch(msg => {
                        if (msg?.msg) {
                            this.$message.error(msg?.msg);
                        }
                    });
				}
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 分页方法
		paginChange(page, pageSize) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.conPO.pageNum = page;
                this.conPO.pageSize = pageSize;
                this.getClientInfo();
			} else {
				this.paginationKey = new Date().getTime();
				this.$refs.loginModal.visible = true;
			}
		},
	},
};
</script>

<style lang="less">
@import "./index.less";
</style>
